
.ArchiveProject{
  border-top: 1px solid var(--dark);
}

.ArchiveProject:last-of-type{
  border-bottom: 1px solid black;
}

.ArchiveProject .project_title{
  font-size: var(--font__medium);
  font-weight: 600;
  line-height: 1.25;
}

.ArchiveProject td{
  text-align: left;
  padding: 30px 20px 30px 0;
}

.ArchiveProject ul li{
  font-size: var(--font__xsmall);
}

.ArchiveProject .Links svg{
  width: 2.2rem;
  height: auto;
}

@media only screen
and (max-width: 650px) {

  .ArchiveProject .project_title{
    font-size: var(--font__normal);
    font-weight: 500;
  }

  .ArchiveProject td{
    padding: 20px 5px;
  }

  .ArchiveProject .project__tech{
    display: none;
  }
}
