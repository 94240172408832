footer{
    text-align: center;
    padding: 15px 0;
}

footer span{
    color: #b31415;
}

@media only screen
and (min-width : 851px)
and (max-width : 1024px){
    footer{
        padding: 60px 0;
    }
}

@media only screen 
and (min-width: 651px)
and (max-width: 850px){
    footer{
        padding: 60px 0;
    }
}

@media only screen
and (max-width: 650px){
    footer{
        padding: 60px 0;
    }   
}