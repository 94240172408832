.email__desk{
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid;
}

.icon__email{
    background-color: var(--dark);
    top: 0;
    display: flex;
    justify-content: center;
    padding: 17px 15px;
}

.icon__email svg{
    width: 30px;
    height: auto;
}

.email__mob{
    display: none;
    position: relative;
    top: 30px;
    left: 0px;
}

.email__mob::before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 1px;
    width: 30px;
    margin-right: 15px;
    background: var(--dark);
}


@media only screen
and (max-width: 850px){
    .email__desk{
        display: none;
    }

    .email__mob{
        display: block;
    }
}
