.experience {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 20px
}

.experience .detail {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

