.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
}

.contact h1{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 7rem;

    text-decoration: underline;
    text-transform: capitalize;

    background: linear-gradient(to left, #00EEFF 0%, #263bd9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
}

.contact h1::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 15%;
    background: linear-gradient(to left, #00EEFF 0%, #263bd9 100%);
}

.contact p{
    width: 40%;
    margin: 0 auto;
    font-weight: 400;
    text-align: center;
}

.contact a{
    margin: 50px auto;
}

@media only screen
and (min-width : 851px)
and (max-width : 1024px) {

    .contact{
        padding: 0 80px;
    }

    .contact h1{
        font-size: 5rem;
    }

    .contact p{
        width: 100%;
        margin-top: 10px;
    }

}

@media only screen 
and (min-width: 651px)
and (max-width: 850px){

    .contact{
        padding: 0 70px;
    }

    .contact h1{
        font-size: 4rem;
    }

    .contact p{
        width: 100%;
        margin-top: 10px;
    }
}

@media only screen 
and (max-width: 650px){

    .contact{
        padding: 0 20px;
    }

    .contact h1{
        font-size: 4rem;
    }

    .contact p{
        width: 100%;
        margin-top: 10px;
    }
}